@import url(https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&display=swap);
* {
    box-sizing: border-box;
    font-family: 'Arimo', sans-serif;
}

body {
    position: relative;
    background-size: 100%;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
}

.main-panel {
    width: 400px;
    z-index: 100000;
    box-sizing: border-box;
    position: absolute;
    right: -100%;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 30%, rgba(0, 0, 0, 0.5) calc(100% - 65px), rgba(0, 0, 0, 0.6) 100%);
    display: flex;
    flex-direction: column;
    color: #fff;
}

.main-panel a {
    color: #fff;
    text-decoration: none;
}

.filter output {
    color: #fff;
}

.main-panel__wrap {
    height: 100%;
    padding: 11px;
}

.main-panel__top {
    display: flex;
    align-items: center;
}

.main-panel__menu-btn {
    cursor: pointer;
    background: url(/static/media/menu.5a3d4a65.svg);
    width: 20px;
    height: 18px;
    background-size: 100%;
}

.main-panel__top-search {
    height: 8px;
    width: 254px;
    padding: 12px 0 12px 12px;
}

.main-panel__top-logo {
    margin-left: auto;
    width: 100px;
    height: 40px;
    background: url(/static/media/logo_varna.39cc98d8.png) no-repeat;
    background-size: 100% auto;
    background-position: center;
}

.main-panel__search-wrap {
    display: flex;
    margin-top: 16px;
    overflow: hidden;
    max-height: 100%;
}

.main-panel__search-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow-y: scroll;
    width: 272px;
    flex-shrink: 0;
    overflow-x: hidden;
    margin-bottom: 40px;
}

.main-panel__search-text {
    font-size: 18px;
    text-align: center;
}

.main-panel__search-item {
    position: relative;
    cursor: pointer;
    display: flex;
    background-size: 100%;
    background-repeat: no-repeat;
    margin-bottom: 12px;
    width: 273px;
    height: 154px;
    flex-shrink: 0;
}

.main-panel__categories {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0 0 0 5px;
    padding-right: 5px;
    width: 91px;
    margin-bottom: 60px;
    flex-shrink: 0;
}

.main-panel__cat-item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin: 0 0 5px 0;
    width: 100%;
    flex-shrink: 0;
}

.main-panel__cat-item:hover .cat-icon__img svg {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin: 0 0 28px 0;
    width: 100%;
    transform: scale(1.2);
    transition: 0.5s;
}

.main-panel__cat-item:last-child {
    margin-bottom: 0;
}

.main-panel__cat-subcat {
    display: none;
}

.main-panel__cat-subcat.active {
    display: flex;
}

.cat-icon__img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    position: absolute;
    top: 50%;
    margin-top: -23px;
    right: 50%;
    margin-right: -22px;
}

.cat-icon__img img {
    display: block;
    width: 100%;
    margin-bottom: 5px;
}

.cat-icon__img {
    fill: #fff;
}

.hotels .cat-icon__svg {
    fill: #a000ff;
}

.real-estate .cat-icon__svg {
    fill: #ff0000;
}

.beaches .cat-icon__svg {
    fill: #ffde00;
}

.tourist-routes .cat-icon__svg {
    fill: #00ac1f;
}

.food .cat-icon__svg {
    fill: #ff6400;
}

.education .cat-icon__svg {
    fill: rgba(153, 153, 153, 1);
}

.sport .cat-icon__svg {
    fill: rgba(255, 248, 128, 1);
}

.religious .cat-icon__svg {
    fill: rgba(240, 188, 30, 1);
}

.shops .cat-icon__svg {
    fill: rgba(255, 126, 217, 1);
}

.sights .cat-icon__svg {
    fill: #5c9bbe;
}

.etertainment .cat-icon__svg {
    fill: rgba(255, 0, 255, 1);
}

.business .cat-icon__svg {
    fill: #00ab60;
}

.live .cat-icon__svg {
    fill: #ff0000;
}

.main-panel__cat-item-title {
    margin-top: 5px;
}

.main-panel__categories::-webkit-scrollbar-track,
.main-panel__search-container::-webkit-scrollbar-track {
    border-radius: 4px;
    -webkit-border-radius: 4px;
}

.main-panel__categories::-webkit-scrollbar,
.main-panel__search-container::-webkit-scrollbar {
    width: 3px;
    background: #354050;
}

.main-panel__categories::-webkit-scrollbar-thumb,
.main-panel__search-container::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.3);
}

.main-panel__categories:hover::-webkit-scrollbar-thumb,
.main-panel__search-container::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
}

.cat-icon {
    position: relative;
}

.main-panel__item-title {
    display: flex;
    flex-direction: column;
    margin: auto 0 12px 7px;
}

.main-panel__item-title .title-district {
    text-transform: uppercase;
    font-weight: bold;
}

.main-menu-preloader {
    position: absolute;
    top: 131px;
    height: 70px;
    width: 70px;
    left: 50%;
    margin-left: -35px;
}

.main-panel__search-container {
    position: relative;
}

.main-panel__subcats {
    display: flex;
    flex-direction: column;
}

.main-panel__subcat-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: auto;
    color: #fff;
    padding: 4px 3px;
    margin: 8px 0 0 0;
    font-size: 10px;
    word-break: break-word;
}

.main-panel__cat-title {
    font-size: 12px;
    word-break: break-all;
}

.main-panel__cat-item.food .main-panel__subcat-title {
    border: 2px solid #ff6400;
}

.main-panel__cat-item.sights .main-panel__subcat-title {
    border: 2px solid #5c9bbe;
}

.main-panel__cat-item.religious .main-panel__subcat-title {
    border: 2px solid #f0bc1e;
}

.main-panel__cat-item.live .main-panel__subcat-title {
    border: 2px solid #ff0000;
}

.main-panel__cat-item.real-estate .main-panel__subcat-title {
    border: 2px solid #ff0000;
}


.main-panel__subcat-title {
    border: 2px solid rgba(255, 126, 217, 1);
}

.main-panel__subcat.main-panel__subcat_level2 .main-panel__subcat-title {
    border: 2px solid #0000ff;
}
.main-panel__cat-item.business .main-panel__subcat-title {
    border: 2px solid #00ab60;
}

.object-loads {
    margin-top: -20px;
    width: 100%;
    height: 50px;
    flex-shrink: 0;
}

.main-panel__bottom {
    display: flex;
    position: absolute;
    bottom: 10px;
    left: 35px;
    height: 40px;
}

.main-panel__back-btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: solid 1px #888888;
    background: #1a1716;
    z-index: 20;
}

.main-panel__back-btn svg {
    fill: #fff;
    width: 70%;
    height: 70%;
    margin-bottom: 5px;
}

.main-panel__close-btn {
    position: absolute;
    left: 60px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: solid 1px #888888;
    background: #1a1716;
    z-index: 20;
}

.main-panel__close-btn svg {
    fill: #fff;
    width: 70%;
    height: 70%;
}

.main-panel__back-back svg {
    fill: #fff;
    width: 70%;
    height: 70%;
    margin-bottom: 5px;
}

.main-panel .st0 {
    display: block;
    fill: #fff;
    stroke: none;
}

.main-panel__item-img {
    position: relative;
    width: 138px;
    height: 138px;
    background-size: 100%;
    background-position: 100%;
    flex-shrink: 0;
}

.main-panel__item-container {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin-left: 7px;
    max-height: 138px;
}

.main-panel__item-price {
    color: #00ff00;
    font-size: 16px;
    margin-top: auto;
    margin-bottom: 5px
}

.main-panel__item-bedrooms {
    margin-top: 3px;
    display: flex;
    align-items: center;
}

.main-panel__bedrooms-img {
    width: 15px;
    height: 15px;
    margin-left: 5px;
    background: url(/static/media/beds_icon_white.37bb0051.svg);
    background-position: center;
    background-size: 100%;
}

.main-panel__item-area {
    margin-top: 3px;
}

.main-panel__item-cat {
    margin-top: 1px;
}

.main-panel__item-container .main-panel__item-title {
    font-size: 9px;
    margin: 0;
}

.main-panel__item-price .price {
    font-weight: bold;
    color: #fff;
}

.main-panel__item-id {
    position: absolute;
    top: 6px;
    right: 5px;
    color: white;
    z-index: 3;
    font-size: 11px;
    text-shadow: 1px 1px 2px black;
}

.main-panel__search-item.estate {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 6%, rgba(0, 0, 0, 0) 87%, rgba(0, 0, 0, 1) 110%);
    height: auto;
}

.main-panel__item-sale {
    z-index: 15;
    background-image: url(/static/media/sale_icon.3402bd33.svg);
    height: 48px;
    width: 48px;
    position: absolute;
    top: -1px;
    background-size: contain;
    left: -1px;
    transition: all 0.3s ease;
}

.main-panel__item-loc-wrap {
    margin-top: 6px;
    display: flex;
}

.main-panel__item-rent {
    z-index: 15;
    background-image: url(/static/media/rent_icon.457c7308.svg);
    height: 48px;
    width: 48px;
    position: absolute;
    top: -1px;
    background-size: contain;
    left: -1px;
    transition: all 0.3s ease;
}

.main-panel__top input::-webkit-input-placeholder {
    color: #000;
}

.main-panel__top input:-ms-input-placeholder {
    color: #000;
}

.main-panel__top input::placeholder {
    color: #000;
}

.subcat-enter {
    opacity: 0;
    transform: scale(0.9);
}

.subcat-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.subcat-exit {
    height: 0;
    opacity: 1;
    transition: opacity 300ms,
        transform 300ms;
}

.subcat-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}



.menu-transition-enter-done {
    right: 0;
    transition: all 0.3s ease-out;
    opacity: 1;
}



.menu-transition-exit {
    right: -100%;
     transition: all 0.3s ease-out;
}


.main-panel__subcat-title.active {
    font-size: 14px;
}

.object-line {
    position: absolute;
    width: 100%;
    height: 1px;
    background: #fff;
    z-index: 10;
}

.estate .object-line_top {
    top: 3px;
}

.estate .object-line_bottom {
    bottom: 3px;
}

.object-line_bottom {
    bottom: 0px;
}

.terminal .main-panel__top-search {
    display: none;
}

.real-estate .main-panel__subcat_level2 .main-panel__subcat-title:nth-child(1),
.real-estate .main-panel__subcat_level2 .main-panel__subcat-title:nth-child(2) {
    display: none;
}

.category-search-btn {
    display: block;
    height: 44px;
    width: 44px;
    margin: auto;
    cursor: pointer;
    border-radius: 50% 50% 50% 0;
    position: absolute;
    top: 0;
    right: 25px;
    bottom: 40px;
    margin: auto;
    z-index: 10000000000;
}
.animation-border {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    left: -6px;
    bottom: 0px;
    right: 0px;
    margin: auto;
    animation: circle-animation-1 1.5s infinite ease-in-out;
    box-sizing: content-box;
    border: 2px solid rgba(255, 0, 0, 0.3);
}

.btn-animation {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: rgba(255, 0, 0, 0.5);
    position: absolute;
    top: 0px;
    left: -4px;
    bottom: 0px;
    right: 0px;
    margin: auto;
}
.animation-background {
    width: 63px;
    height: 63px;
}
.category-search-btn-img {
    fill: #fff;
    width: 60%;
    height: auto;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    margin: auto auto auto 11px;
}

.category-search-btn-text {
    z-index: 1;
    color: white;
    position: absolute;
    top: 42px;
    font-size: 14px;
    line-height: 12px;
    text-align: center;
    left: 0;
    right: 0;
}

.main-pano {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border: none;
}

.filter-btn {
    text-align: center;
    padding: 4px 0;
    border: 1px solid #fff;
    border-radius: 10px;
    width: 96%;
    margin-bottom: 10px;
    cursor: pointer;
}

@media screen and (max-width: 1200px) {
    .main-panel__bottom {
        bottom: 40px;
    }
    .filter-btn {
        font-size: 14px;
    }
}

@media screen and (max-width: 720px) {

    .category-search-btn {
        display: block;
        height: 46px;
        width: 46px;
        margin: auto;
        cursor: pointer;
        border-radius: 50% 50% 50% 0;
        position: absolute;
        top: 0;
        right: 25px;
        bottom: 40px;
        margin: auto;
        z-index: 10000000000;
    }

    .animation-border {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        position: absolute;
        top: 0px;
        left: -6px;
        bottom: 0px;
        right: 0px;
        margin: auto;
        animation: circle-animation-1 1.5s infinite ease-in-out;
        box-sizing: content-box;
        border: 2px solid rgba(255, 0, 0, 0.3);
    }

    .btn-animation {
        width: 52px;
        height: 52px;
        border-radius: 50%;
        background-color: rgba(255, 0, 0, 0.5);
        position: absolute;
        top: 0px;
        left: -4px;
        bottom: 0px;
        right: 0px;
        margin: auto;
    }

    .animation-background {
        width: 59px;
        height: 59px;
    }

    .category-search-btn-img {
        fill: #fff;
        width: 60%;
        height: auto;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin-left: 0;
        left: 0;
        z-index: 1;
    }

    .category-search-btn-text {
        z-index: 1;
        color: white;
        position: absolute;
        top: 42px;
        font-size: 14px;
        line-height: 12px;
        text-align: center;
        left: 0;
        right: 0;
    }

    .main-panel__top-search {
        width: 230px;
        margin-right: 10px;
    }

    .main-panel__item-title .title-district {
        font-size: 10px;
    }

    .main-panel__item-img {
        background-repeat: no-repeat;
        width: 94px;
    }

    .main-panel__categories {
        width: 71px;
        max-width: 30%;
        flex-shrink: 0;
    }

    .main-panel__search-container {
        width: 80%;
        flex-shrink: 0;
        padding-right: 5px;
        margin-left: auto;
        padding-right: 5px;
        flex-shrink: 1;
        overflow-x: hidden;
    }

    .main-panel__search-item {
        position: relative;
        width: 100%;
        height: 133px;
        padding-bottom: 50%;
    }

    .cat-icon__img {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 50%;
        margin-top: -20px;
        right: 50%;
        margin-right: -20px;
    }

    .main-panel__subcat-title {
        width: 100%;
    }

    .main-panel__cat-title {
        word-wrap: break-word;
    }

    .main-panel__subcat-title {
        font-size: 10px;
    }

    .main-panel__cat-title {
        font-size: 10px;
    }

    .main-panel__item-title {
        position: absolute;
        bottom: 0;
    }

    .main-panel__search-item.estate {
        padding-bottom: 0;
    }

    .main-panel__search-item.estate .main-panel__item-title {
        position: static;
    }

    .main-panel__search-item.estate .main-panel__item-img {
        width: 117px;
        height: 117px;
        margin: auto 0;
    }

    .main-panel {
        height: 100%;
    }

    .main-panel__wrap {
        padding-bottom: 0;
    }

    .main-panel__bottom {
        bottom: 40px;
    }

    .main-panel__search-item {
        height: 88px;
    }
}


@media screen and (max-width: 480px) {
    .main-panel {
        width: 100%;
    }
}
.filter {
    padding: 30px 0 0 0;
}

.filter-field {
    margin-bottom: 15px;
}

.range-input {
    margin-top: 15px;
}

.filter-field label {
    font-weight: bold;
    font-size: 18px;
}

.filter output {
    display: block;
    margin-top: 15px;
    font-size: 18px;
}

.filter select {
    width: 100%;
    height: 33px;
    font-size: 16px;
    color: #000;
}

.filter__buttons-panel {
    margin-top: 15px;
    display: flex;
}

.filter__btn {
    background: red;
    padding: 5px 10px;
    margin-right: 15px;
    cursor: pointer;
    text-align: center;
    font-size: 17px;
}

@media(max-width: 720px) {
    .filter .filter-field_range {
        padding: 0 12px;
    }
}
.modeBtn {
    position: absolute;
    width: 50px;
    height: 50px;
    background: #fff;
    z-index: 1090000;
}
