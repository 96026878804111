.filter {
    padding: 30px 0 0 0;
}

.filter-field {
    margin-bottom: 15px;
}

.range-input {
    margin-top: 15px;
}

.filter-field label {
    font-weight: bold;
    font-size: 18px;
}

.filter output {
    display: block;
    margin-top: 15px;
    font-size: 18px;
}

.filter select {
    width: 100%;
    height: 33px;
    font-size: 16px;
    color: #000;
}

.filter__buttons-panel {
    margin-top: 15px;
    display: flex;
}

.filter__btn {
    background: red;
    padding: 5px 10px;
    margin-right: 15px;
    cursor: pointer;
    text-align: center;
    font-size: 17px;
}

@media(max-width: 720px) {
    .filter .filter-field_range {
        padding: 0 12px;
    }
}